import { API } from 'aws-amplify';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import packageJson from '../../package.json';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Alert } from '@material-ui/lab';
import ReportSidebar from '../components/ReportSidebar';
import { trackReportVisit } from '../state/analytics/actions';
import { InsertDriveFileRounded } from '@material-ui/icons';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

const fileTypes = {
  word: 'WORD',
  excel: 'EXCEL',
  powerpoint: 'POWERPOINT',
  pdf: 'PDF',
  unsupported: 'UNSUPPORTED',
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: '2rem',
  },
  icon: {
    fontSize: '5rem',
    color: '#0f70e0',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '0.5rem',
  },
  button: {
    backgroundColor: theme.palette.custom.blue2,
    borderRadius: 20,
    color: 'white',
    fontFamily: 'Nunito Sans Black',
    padding: '0.5rem 2.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: theme.palette.custom.blue2,
    },
  },
});

const handleDownload = (fileUrl) => {
  window.open(fileUrl, '_blank').focus();
};

const Report = ({ reportId, doTrackReportVisit, classes }) => {
  const { userId, jwtToken } = useSelector((state) => state.user);
  const [report, setReport] = useState(null);
  const [loader, setLoader] = useState(false);
  const [height, setHeight] = useState(0);

  const containerRef = useRef(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {renderDefaultToolbar((slot) => ({
          ...slot,
          Open: () => <></>,
          OpenMenuItem: () => <></>,
          SwitchTheme: () => <></>,
          SwitchThemeMenuItem: () => <></>,
        }))}
      </Toolbar>
    ),
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  useEffect(() => {
    (async () => {
      setLoader(true);

      try {
        const report = await API.get('users', `/users/${userId}/reports/${reportId}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (!report) throw new Error('report not found');

        await doTrackReportVisit(report._id, report.title);

        setReport(report);
      } catch (e) {
        console.error(e);

        navigate('/reports');
      }

      setLoader(false);
    })();
  }, []);

  useEffect(() => {
    // Ensure the ref has been assigned to an actual DOM element
    if (containerRef.current) {
      // Get the height of the element
      const elementHeight = containerRef.current.clientHeight;
      setHeight(elementHeight);
    }
  }, []);

  const url = useMemo(() => {
    return report?.report || report?.brochure;
  }, [report]);

  const fileType = useMemo(() => {
    if (!url) return '';
    // Extracting file extension from the URL
    const fileUrl = new URL(url);
    const extension = fileUrl.pathname.split('.').pop().toLowerCase();

    switch (extension) {
      case 'pdf':
        return fileTypes.pdf;
      case 'doc':
      case 'docx':
        return fileTypes.word;
      case 'xls':
      case 'xlsx':
        return fileTypes.excel;
      case 'ppt':
      case 'pptx':
        return fileTypes.powerpoint;
      default:
        return fileTypes.unsupported;
    }
  }, [url]);

  return (
    <div
      style={{
        display: 'flex',
      }}
      ref={containerRef}
    >
      <ReportSidebar downloadFile={() => handleDownload(url)} />

      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {loader ? (
          <>
            <CircularProgress size="80px" thickness={1.5} style={{ margin: 'auto' }} />
          </>
        ) : (
          <>
            {url && fileType === fileTypes.pdf && (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
              >
                <div style={{ height }}>
                  <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} initialPage={1}/>
                </div>
              </Worker>
            )}

            {url && [fileTypes.excel, fileTypes.word, fileTypes.powerpoint].includes(fileType) && (
              <div style={{ position: 'relative', flexGrow: 1 }}>
                {/* This div is there to hide the previewer footer for excel */}
                {/* {fileType === fileTypes.excel && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      zIndex: 1000,
                      width: '100%',
                      height: 26,
                      backgroundColor: 'black',
                    }}
                  />
                )} */}
                {/* Replacing `embed` with `view` in the URL will provide additional functionalities, making it more similar to the full Office experience. */}
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}&activeCell=A1&wdHideToolbars=true`}
                  width="100%"
                  height="100%"
                >
                  This is an embedded{' '}
                  <a target="_blank" href="http://office.com" rel="noreferrer">
                    Microsoft Office
                  </a>{' '}
                  document, powered by{' '}
                  <a target="_blank" href="http://office.com/webapps" rel="noreferrer">
                    Office Online
                  </a>
                  .
                </iframe>
              </div>
            )}

            {report && url && fileType === fileTypes.unsupported && (
              <div className={classes.container}>
                <InsertDriveFileRounded className={classes.icon} />
                <p className={classes.title}>{report.title}</p>
                <p>
                  A preview is not available for this file type. Please download the file to view
                  it.
                </p>
                <Button className={classes.button} onClick={() => handleDownload(url)}>
                  Download File
                </Button>
              </div>
            )}

            {!url && <Alert severity="warning">No file specified.</Alert>}
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doTrackReportVisit: (id, name) => dispatch(trackReportVisit(id, name)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Report));
